
// ChallengeModeID: {1: CriteriaCount[0], 2: CriteriaCount[1], 3: CriteriaCount[2]}, // Name (MapID)
export const DungeonChestTimes = {
  2:   {1: 1800, 2: 1440, 3: 1080}, // Temple of the Jade Serpent (960)
  56:  {1: 2700, 2: 1260, 3: 720},  // Stormstout Brewery (961)
  57:  {1: 2700, 2: 1320, 3: 780},  // Gate of the Setting Sun (962)
  58:  {1: 3600, 2: 2100, 3: 1260}, // Shado-Pan Monastery (959)
  59:  {1: 3000, 2: 1800, 3: 1050}, // Siege of Niuzao Temple (1011)
  60:  {1: 2700, 2: 1440, 3: 720},  // Mogu'shan Palace (994)
  76:  {1: 3300, 2: 1980, 3: 1140}, // Scholomance (1007)
  77:  {1: 2700, 2: 1320, 3: 780},  // Scarlet Halls (1001)
  78:  {1: 2700, 2: 1320, 3: 780},  // Scarlet Monastery (1004)
  161: {1: 3400, 2: 1700, 3: 1020}, // Skyreach (1209)
  163: {1: 3600, 2: 2100, 3: 1320}, // Bloodmaul Slag Mines (1175)
  164: {1: 3300, 2: 1920, 3: 1140}, // Auchindoun (1182)
  165: {1: 1980, 2: 1584, 3: 1188}, // Shadowmoon Burial Grounds (1176)
  166: {1: 1800, 2: 1440, 3: 1080}, // Grimrail Depot (1208)
  167: {1: 5100, 2: 2580, 3: 1500}, // Upper Blackrock Spire (1358)
  168: {1: 1980, 2: 1584, 3: 1188}, // The Everbloom (1279)
  169: {1: 1800, 2: 1440, 3: 1080}, // Iron Docks (1195)
  197: {1: 2100, 2: 1680, 3: 1260}, // Eye of Azshara (1456)
  198: {1: 1800, 2: 1440, 3: 1080}, // Darkheart Thicket (1466)
  199: {1: 2160, 2: 1728, 3: 1296}, // Black Rook Hold (1501)
  200: {1: 2280, 2: 1824, 3: 1368}, // Halls of Valor (1477)
  206: {1: 1980, 2: 1584, 3: 1188}, // Neltharion's Lair (1458)
  207: {1: 1980, 2: 1584, 3: 1188}, // Vault of the Wardens (1493)
  208: {1: 1440, 2: 1152, 3: 864},  // Maw of Souls (1492)
  209: {1: 2700, 2: 2160, 3: 1620}, // The Arcway (1516)
  210: {1: 1800, 2: 1440, 3: 1080}, // Court of Stars (1571)
  227: {1: 2520, 2: 2016, 3: 1512}, // Return to Karazhan: Lower (1651)
  233: {1: 2100, 2: 1680, 3: 1260}, // Cathedral of Eternal Night (1677)
  234: {1: 2100, 2: 1680, 3: 1260}, // Return to Karazhan: Upper (1651)
  239: {1: 2100, 2: 1680, 3: 1260}, // Seat of the Triumvirate (1753)
  244: {1: 1800, 2: 1440, 3: 1080}, // Atal'Dazar (1763)
  245: {1: 1800, 2: 1440, 3: 1080}, // Freehold (1754)
  246: {1: 2160, 2: 1728, 3: 1296}, // Tol Dagor (1771)
  247: {1: 2340, 2: 1872, 3: 1404}, // The MOTHERLODE!! (1594)
  248: {1: 2220, 2: 1776, 3: 1332}, // Waycrest Manor (1862)
  249: {1: 2520, 2: 2016, 3: 1512}, // Kings' Rest (1762)
  250: {1: 2160, 2: 1728, 3: 1296}, // Temple of Sethraliss (1877)
  251: {1: 1800, 2: 1440, 3: 1080}, // The Underrot (1841)
  252: {1: 2520, 2: 2016, 3: 1512}, // Shrine of the Storm (1864)
  353: {1: 1980, 2: 1584, 3: 1188}, // Siege of Boralus (1822)
  369: {1: 2280, 2: 1824, 3: 1368}, // Operation: Mechagon - Junkyard (2097)
  370: {1: 1920, 2: 1536, 3: 1152}, // Operation: Mechagon - Workshop (2097)
  375: {1: 1800, 2: 1440, 3: 1080}, // Mists of Tirna Scithe (2290)
  376: {1: 1860, 2: 1488, 3: 1116}, // The Necrotic Wake (2286)
  377: {1: 2580, 2: 2064, 3: 1548}, // De Other Side (2291)
  378: {1: 1920, 2: 1536, 3: 1152}, // Halls of Atonement (2287)
  379: {1: 2280, 2: 1824, 3: 1368}, // Plaguefall (2289)
  380: {1: 2460, 2: 1968, 3: 1476}, // Sanguine Depths (2284)
  381: {1: 2340, 2: 1872, 3: 1404}, // Spires of Ascension (2285)
  382: {1: 2280, 2: 1824, 3: 1368}, // Theater of Pain (2293)
  391: {1: 2340, 2: 1872, 3: 1404}, // Tazavesh: Streets of Wonder (2441)
  392: {1: 1800, 2: 1440, 3: 1080}, // Tazavesh: So'leah's Gambit (2441)
  399: {1: 1800, 2: 1440, 3: 1080}, // Ruby Life Pools (2521)
  400: {1: 2400, 2: 1920, 3: 1440}, // The Nokhud Offensive (2516)
  401: {1: 2250, 2: 1800, 3: 1350}, // The Azure Vault (2515)
  402: {1: 1920, 2: 1536, 3: 1152}, // Algeth'ar Academy (2526)
  403: {1: 2100, 2: 1680, 3: 1260}, // Uldaman: Legacy of Tyr (2451)
  404: {1: 1980, 2: 1584, 3: 1188}, // Neltharus (2519)
  405: {1: 2100, 2: 1680, 3: 1260}, // Brackenhide Hollow (2520)
  406: {1: 2100, 2: 1680, 3: 1260}, // Halls of Infusion (2527)
  438: {1: 1800, 2: 1440, 3: 1080}, // The Vortex Pinnacle (657)
  456: {1: 2040, 2: 1632, 3: 1224}, // Throne of the Tides (643)
  463: {1: 2040, 2: 1632, 3: 1224}, // Dawn of the Infinite: Galakrond's Fall (2579)
  464: {1: 2160, 2: 1728, 3: 1296}, // Dawn of the Infinite: Murozond's Rise (2579)
  499: {1: 1800, 2: 1440, 3: 1080}, // Priory of the Sacred Flame (2649)
  500: {1: 1800, 2: 1440, 3: 1080}, // The Rookery (2648)
  501: {1: 1980, 2: 1584, 3: 1188}, // The Stonevault (2652)
  502: {1: 2100, 2: 1680, 3: 1260}, // City of Threads (2669)
  503: {1: 1800, 2: 1440, 3: 1080}, // Ara-Kara, City of Echoes (2660)
  504: {1: 1800, 2: 1440, 3: 1080}, // Darkflame Cleft (2651)
  505: {1: 1860, 2: 1488, 3: 1116}, // The Dawnbreaker (2662)
  506: {1: 1800, 2: 1440, 3: 1080}, // Cinderbrew Meadery (2661)
  507: {1: 2040, 2: 1632, 3: 1224}, // Grim Batol (670)
  999: {1: 0, 2: 0, 3: 0}           // Unknown
}

export const Dungeons = [
  {
    mapId: 9999,
    challengeModeId: 999,
    name: '???',
    shortName: '???',
    icon: require('@/assets/dungeons/unknown.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 1458,
    challengeModeId: 206,
    name: "Neltharion's Lair",
    shortName: 'NL',
    icon: require('@/assets/dungeons/neltharions-lair.jpg'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center -14px;'
  },
  {
    mapId: 1754,
    challengeModeId: 245,
    name: 'Freehold',
    shortName: 'FH',
    icon: require('@/assets/dungeons/freehold.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 1841,
    challengeModeId: 251,
    name: 'Underrot',
    shortName: 'UNDR',
    icon: require('@/assets/dungeons/underrot.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center -10px;'
  },
  {
    mapId: 1822,
    challengeModeId: 353,
    name: 'Siege of Boralus',
    shortName: 'SIEGE',
    icon: require('@/assets/dungeons/siege-of-boralus.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center -10px;'
  },
  {
    mapId: 2290,
    challengeModeId: 375,
    name: 'Mists of Tirna Scithe',
    shortName: 'MISTS',
    icon: require('@/assets/dungeons/mists-of-tirna-scithe.jpg'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 2286,
    challengeModeId: 376,
    name: 'The Necrotic Wake',
    shortName: 'NW',
    icon: require('@/assets/dungeons/necrotic-wake.jpg'),
    iconStyle: 'object-position: center top;',
    sidebarIconStyle: 'object-position: center top;'
  },
  {
    mapId: 2521,
    challengeModeId: 399,
    name: 'Ruby Life Pools',
    shortName: 'RLP',
    icon: require('@/assets/dungeons/ruby-life-pools.png'),
    iconStyle: 'object-position: center -24px;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 2516,
    challengeModeId: 400,
    name: 'The Nokhud Offensive',
    shortName: 'NO',
    icon: require('@/assets/dungeons/nokhud-offensive.png'),
    iconStyle: 'object-position: center top;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 2515,
    challengeModeId: 401,
    name: 'Azure Vault',
    shortName: 'AV',
    icon: require('@/assets/dungeons/azure-vault.png'),
    iconStyle: 'object-position: center top;',
    sidebarIconStyle: 'object-position: center top;'
  },
  {
    mapId: 2526,
    challengeModeId: 402,
    name: "Algeth'ar Academy",
    shortName: 'AA',
    icon: require('@/assets/dungeons/algethar-academy.png'),
    iconStyle: 'object-position: center top;',
    sidebarIconStyle: 'object-position: center top;'
  },
  {
    mapId: 2451,
    challengeModeId: 403,
    name: 'Uldaman: Legacy of Tyr',
    shortName: 'ULD',
    icon: require('@/assets/dungeons/uldaman.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center center;',
  },
  {
    mapId: 2519,
    challengeModeId: 404,
    name: 'Neltharus',
    shortName: 'NELT',
    icon: require('@/assets/dungeons/neltharus.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center -16px;'
  },
  {
    mapId: 2520,
    challengeModeId: 405,
    name: 'Brackenhide Hollow',
    shortName: 'BH',
    icon: require('@/assets/dungeons/brackenhide-hollow.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center -10px;'
  },
  {
    mapId: 2527,
    challengeModeId: 406,
    name: 'Halls of Infusion',
    shortName: 'HOI',
    icon: require('@/assets/dungeons/halls-of-infusion.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 657,
    challengeModeId: 438,
    name: 'The Vortex Pinnacle',
    shortName: 'VP',
    icon: require('@/assets/dungeons/vortex-pinnacle.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 2652,
    challengeModeId: 501,
    name: 'The Stonevault',
    shortName: 'SV',
    icon: require('@/assets/dungeons/stonevault.png'),
    iconStyle: 'object-position: center top;',
    sidebarIconStyle: 'object-position: center top;'
  },
  {
    mapId: 2669,
    challengeModeId: 502,
    name: 'City of Threads',
    shortName: 'COT',
    icon: require('@/assets/dungeons/city-of-threads.png'),
    iconStyle: 'object-position: center top;',
    sidebarIconStyle: 'object-position: center top;'
  },
  {
    mapId: 2660,
    challengeModeId: 503,
    name: 'Ara-Kara, City of Echoes',
    shortName: 'ARAK',
    icon: require('@/assets/dungeons/ara-kara.png'),
    iconStyle: 'object-position: center bottom;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 2662,
    challengeModeId: 505,
    name: 'The Dawnbreaker',
    shortName: 'DAWN',
    icon: require('@/assets/dungeons/dawnbreaker.png'),
    iconStyle: 'object-position: center center;',
    sidebarIconStyle: 'object-position: center center;'
  },
  {
    mapId: 670,
    challengeModeId: 507,
    name: 'Grim Batol',
    shortName: 'GB',
    icon: require('@/assets/dungeons/grim-batol.png'),
    iconStyle: 'object-position: center bottom;',
    sidebarIconStyle: 'object-position: center bottom;'
  }
]
